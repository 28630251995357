/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Components/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'LtrGlobalStyles',
        import: () => import('@stories/Components/Global/LtrGlobalStyles/LtrGlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ChatbotStyles',
        import: () => import('@stories/Components/Global/ChatbotStyles/ChatbotStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Components/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ContentEvaluation',
        import: () => import('@stories/Widgets/ContentEvaluation/ContentEvaluation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'KenticoForm',
        import: () => import('@stories/Widgets/KenticoForm/KenticoForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Testimonials',
        import: () => import('@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomeBanner',
        import: () => import('@stories/Widgets/HomeBanner/HomeBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import('@stories/Widgets/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteEvaluation',
        import: () => import('@stories/Components/SiteEvaluation/SiteEvaluation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import('@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuickBanner',
        import: () => import('@stories/Widgets/QuickBanner/QuickBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUsBanner',
        import: () => import('@stories/Widgets/ContactUsBanner/ContactUsBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResult',
        import: () => import('@stories/Components/SearchResult/SearchResult'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedCarousels',
        import: () => import('@stories/Widgets/TabbedCarousels/TabbedCarousels'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventListing',
        import: () => import('@stories/Widgets/EventListing/EventListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CoursesListing',
        import: () => import('@stories/Widgets/CoursesListing/CoursesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsCalendar',
        import: () => import('@stories/Widgets/EventsCalendar/EventsCalendar'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ParticipationListing',
        import: () => import('@stories/Widgets/ParticipationListing/ParticipationListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountLogin',
        import: () => import('@stories/Components/AccountLogin/AccountLogin'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FaqsAccordion',
        import: () => import('@stories/Widgets/FaqsAccordion/FaqsAccordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventBanner',
        import: () => import('@stories/Components/EventBanner/EventBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Components/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Accordion',
        import: () => import('@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PageReader',
        import: () => import('@stories/Components/PageReader/PageReader'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'FooterPageReader',
        import: () => import('@stories/Components/FooterPageReader/FooterPageReader'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ServicesCarousel',
        import: () => import('@stories/Widgets/ServicesCarousel/ServicesCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Articlebanner',
        import: () => import('@stories/Components/ArticleBanner/ArticleBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CardsCarousel',
        import: () => import('@stories/Widgets/CardsCarousel/CardsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconList',
        import: () => import('@stories/Widgets/IconList/IconList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Statistics',
        import: () => import('@stories/Widgets/Statistics/Statistics'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IndicatorsCarousel',
        import: () => import('@stories/Widgets/IndicatorsCarousel/IndicatorsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BodForm',
        import: () => import('@stories/Widgets/BodForm/BodForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsCarousel',
        import: () => import('@stories/Widgets/EventsCarousel/EventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CarsListing',
        import: () => import('@stories/Widgets/CarsListing/CarsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
];
